// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-financial-compliance-js": () => import("./../../../src/pages/financial-compliance.js" /* webpackChunkName: "component---src-pages-financial-compliance-js" */),
  "component---src-pages-harassment-discrimination-courses-js": () => import("./../../../src/pages/Harassment-discrimination-courses.js" /* webpackChunkName: "component---src-pages-harassment-discrimination-courses-js" */),
  "component---src-pages-healthandsafety-js": () => import("./../../../src/pages/healthandsafety.js" /* webpackChunkName: "component---src-pages-healthandsafety-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infosec-data-protection-js": () => import("./../../../src/pages/Infosec-data-protection.js" /* webpackChunkName: "component---src-pages-infosec-data-protection-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-succeedlms-js": () => import("./../../../src/pages/succeedlms.js" /* webpackChunkName: "component---src-pages-succeedlms-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-harassment-js": () => import("./../../../src/templates/harassment.js" /* webpackChunkName: "component---src-templates-harassment-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-security-js": () => import("./../../../src/templates/security.js" /* webpackChunkName: "component---src-templates-security-js" */)
}

