module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SucceedLEARN.com","short_name":"SucceedLEARN","start_url":"/","background_color":"#1a447f","theme_color":"#f68b1e","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3e6d861c96dbe131aff4277b70d7b813"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
